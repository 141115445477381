<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('purchases_orders.purchases_orders') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>


      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('purchases_orders.supplier') }}<span class="text-danger">*</span></label>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <!-- <select name="" id="supplier_id" v-model="data.supplier_id" class="custom-select" :class="validation && validation.supplier_id ? 'is-invalid' : ''">
                        <option v-for="row in suppliers" :value="row.id" :key="row.id">{{ row.full_name }}</option>
                    </select> -->
                    <multiselect v-model="supplier"
                                 :placeholder="$t('purchases_orders.supplier')"
                                 label="name"
                                 track-by="id"
                                 :options="suppliers"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getSuppliers($event)">
                    </multiselect>
                    <span class="form-text text-muted" style="text-align:end">{{
                        $t('type_at_least_three_letters') + ' ' + $t('purchases_invoices.supplier')
                      }}.</span>
                    <span v-if="validation && validation.supplier_id"
                          class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_id[0] }}
                                    </span>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.currency') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select"
                          :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control"
                       :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.shipping_fees') }}</label>
                <input type="number" step="0.1" min="1" v-model="data.shipping_fees" class="form-control"
                       :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.purchase_code') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.purchase_code" class="form-control"
                       :class="validation && validation.purchase_code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_code[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.purchase_date') }}</label>
                <input type="date" v-model="data.purchase_date" class="form-control"
                       :class="validation && validation.purchase_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.valid_until') }}</label>
                <div class="form-group row">
                  <div class="col-sm-10">
                    <input type="number" v-model="data.valid_until" class="form-control"
                           :class="validation && validation.valid_until ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.valid_until"
                          class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.valid_until[0] }}
                                    </span>
                  </div>
                  <span class="col-sm-2">{{ $t('purchases_orders.day_s') }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.branch') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="branch"
                               :placeholder="$t('purchases_orders.branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus"
                                                                                                   style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchases_orders.inventory') }}<span class="text-danger">*</span></label>
                <!-- <select name="" id="inventory_id" v-model="data.inventory_id" class="custom-select" :class="validation && validation.inventory_id ? 'is-invalid' : ''">
                    <option v-for="row in inventories" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select> -->

                <div class="input-group mb-3">
                  <multiselect v-model="inventory"
                               :placeholder="$t('purchases_orders.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus"
                                                                                                      style="padding: 0"></i></a>
                  </div>
                </div>

                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select"
                        :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('purchases_orders.items') }}</h6>
            <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}
            </button>
          </div>
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList"
                   @keyup.alt.46="removeItemRowFromList">
              <thead>
              <tr>
                <th width="250px">{{ $t('items.item_details') }}</th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('purchases_orders.item') }}</span>
                    <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                      <b-icon icon="plus"></b-icon>
                    </a>
                  </div>
                </th>
                <th>{{ $t('purchases_orders.description') }}</th>
                <th>{{ $t('purchases_orders.unit_price') }}</th>
                <th>{{ $t('purchases_orders.qty') }}</th>
                <!--                    <th>{{ $t('purchases_orders.tax_1') }}</th>-->
                <!--                    <th>{{ $t('purchases_orders.tax_2') }}</th>-->
                <!--                    <th>{{ $t('purchases_orders.discount_type') }}</th>-->
                <!--                    <th>{{ $t('purchases_invoices.discount_value') }}</th>-->
                <th>{{ $t('purchases_orders.subtotal') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in items_list" :key="index">
                <td>
                  <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item"
                     class="d-flex cursor-pointer">
                    <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                    <div class="pl-2 pr-2 text-dark">
                      <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}:
                        {{ row.item.sku_code }}</p>
                      <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                      <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}:
                        {{ row.item.sale_price }}</p>
                      <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}:
                        {{ row.item.purchase_price }}</p>
                      <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                        {{ row.item.sum_available_qty }}</p>
                    </div>
                  </a>
                </td>
                <td>
                  <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('purchases_orders.item')">
                    <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                      <option value="name" selected>{{ $t('items.name') }}</option>
                      <option value="sku_code">{{ $t('items.sku_code') }}</option>
                    </select>
                    <multiselect v-model="row.item"
                                 :placeholder="$t('purchases_orders.item')"
                                 :label="row.f_by? row.f_by:'name'"
                                 track-by="id"
                                 :options="items"
                                 :multiple="false"
                                 @input="selectItem(index)"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :internal-search="false"
                                 :show-no-results="false"
                                 @search-change="getItems(row.f_by, $event)">
                    </multiselect>
                  </div>

                </td>
                <td><input v-model="row.description" type="text" class="form-control"></td>
                <td><input v-model="row.unit_price" @input="setValue(index)" type="number" class="form-control">
                </td>
                <td><input v-model="row.qty" @input="setValue(index)" type="number" class="form-control"></td>
                <!--                    <td>-->
                <!--                      <multiselect v-model="row.tax_1"-->
                <!--                                   :placeholder="$t('purchases_orders.tax')"-->
                <!--                                   label="name"-->
                <!--                                   track-by="id"-->
                <!--                                   :options="taxes"-->
                <!--                                   :multiple="false"-->
                <!--                                   :taggable="false"-->
                <!--                                   :show-labels="false"-->
                <!--                                   :show-no-options="false"-->
                <!--                                   :show-no-results="false"-->
                <!--                                   @search-change="getTaxes($event)">-->
                <!--                      </multiselect>-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <multiselect v-model="row.tax_2"-->
                <!--                                   :placeholder="$t('purchases_orders.tax')"-->
                <!--                                   label="name"-->
                <!--                                   track-by="id"-->
                <!--                                   :options="taxes"-->
                <!--                                   :multiple="false"-->
                <!--                                   :taggable="false"-->
                <!--                                   :show-labels="false"-->
                <!--                                   :show-no-options="false"-->
                <!--                                   :show-no-results="false"-->
                <!--                                   @search-change="getTaxes($event)">-->
                <!--                      </multiselect>-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <select name="" id="discount_type" v-model="row.discount_type" class="custom-select">-->
                <!--                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>-->
                <!--                      </select>-->
                <!--                    </td>-->
                <!--                    <td><input v-model="row.discount_val" type="number" class="form-control"></td>-->
                <td>{{ row.subtotal }}</td>
                <td>
                  <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="4"></td>
                <th>{{ $t('purchases_orders.items_total') }}</th>
                <th><span>{{ data.items_total }}</span></th>
                <th></th>
              </tr>
              <!--                  <tr>-->
              <!--                    <td class="border-0" colspan="4"></td>-->
              <!--                    <th>{{ $t('purchases_orders.total_tax') }}(%)</th>-->
              <!--                    <th><span>{{ data.total_taxes }}</span></th>-->
              <!--                    <th class="border-0"></th>-->
              <!--                  </tr>-->
              <tr>
                <td class="border-0" colspan="4"></td>
                <th>{{ $t('purchases_orders.total') }}</th>
                <th><span>{{ total }}</span></th>
                <th class="border-0"></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_orders.notes') }}</label>
                  <textarea name="" id="notes" v-model="data.notes" class="form-control"
                            :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_orders.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('purchases_orders.attachment')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('suppliers.add_new_supplier')">
      <supplier-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewSupplier"></supplier-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import SupplierForm from "@/view/content/forms/SupplierForm";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-purchases-orders",
  components: {'supplier-form': SupplierForm, 'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'purchases/purchase-orders',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'purchases',

      data: {
        purchase_code: null,
        attachment: null,
        items_total: 0,
        total_taxes: 0,
        shipping_fees: 0,
        discount_type: null,
        discount_val: null,
        branch_id: null,
        inventory_id: null,
        supplier_id: null,
        valid_until: null,
        status: 1,
        purchase_date: null,
        notes: null,
        currency_id: null,
        exchange_rate: null,
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      reloadUploadAttachment: true,
      attachment_url: '',
      total: 0,

      suppliers: [],
      currencies: [],
      inventories: [],
      items: [],
      taxes: [],
      branches: [],
      supplier: null,
      branch: null,
      inventory: null,

      validation: null,
      tax_default: null,
      items_list_form: {
        id: null,
        item: null,
        description: null,
        unit_price: null,
        qty: null,
        tax_1: null,
        tax_2: null,
        discount_type: null,
        discount_val: null,
        subtotal: null
      },
      items_list: [],
      discount_types: [
        {id: 1, name: this.$t('discount_types.none')},
        {id: 2, name: this.$t('discount_types.percentage')},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      status_list: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    supplier: function (val) {
      if (val) {
        this.data.supplier_id = val.id;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      }
    },
    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.data.items_total = this.$_.sumBy(this.items_list, (row) => {
            return row.subtotal ? parseFloat(row.subtotal) : 0;
          });
          this.data.items_total = this.data.items_total ? this.data.items_total : 0;
          // this.data.total_taxes = this.$_.sumBy(this.items_list, (row) => {
          //   return row.tax_1 ?  parseFloat(row.tax_1.value_rate): 0;
          // });
          // this.data.total_taxes = this.data.total_taxes ? this.data.total_taxes : 0;
          this.total = this.data.items_total;// + this.data.total_taxes;
        }
      },
      deep: true
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      this.data.status = this.data.status ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'purchases-orders.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      this.data.status = this.data.status ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'purchases-orders.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.purchase_code = response.data.data.purchase_code;
        this.data.attachment = response.data.data.attachment;
        this.data.items_total = response.data.data.items_total;
        this.data.total_taxes = response.data.data.total_taxes;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_val = response.data.data.discount_val;
        this.data.branch_id = response.data.data.branch_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.supplier_id = response.data.data.supplier_id;
        this.data.valid_until = response.data.data.valid_until;
        this.data.status = response.data.data.status;
        this.data.purchase_date = response.data.data.purchase_date;
        this.data.notes = response.data.data.notes;
        this.data.currency_id = response.data.data.currency_id;
        this.branch = response.data.data.branch;
        this.inventory = response.data.data.inventory;
        this.supplier = response.data.data.supplier;
        this.data.exchange_rate = response.data.data.exchange_rate;


        this.items_list = response.data.data.items_list;
        this.attachment_url = response.data.data.attachment_url;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.reloadUploadAttachment = true;
      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/16").then((response) => {
        this.data.purchase_code = response.data.data.code;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;

      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;

      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=3`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    async getDefaultTax() {
      await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
        this.tax_default = response.data.data;
      });
    },

    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },

    loadOptions() {
    },


    addItemRowToList() {
      // this.items_list_form.tax_1 = this.tax_default;
      // this.items_list_form.tax_2 = this.tax_default;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {
        id: null,
        item: null,
        description: null,
        unit_price: null,
        qty: null,
        tax_1: null,
        tax_2: null,
        discount_type: null,
        discount_val: null,
        subtotal: null
      };
    },
    removeItemRowFromList(index) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;
    },
    setValue(index) {
      this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewSupplier(object) {
      let promise = this.getSuppliers();
      Promise.all([promise]).then(() => {
        this.data.supplier_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          // this.items_list[0].tax_1 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.purchases_orders"),
      route: '/purchases/purchases-orders'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getInventories();
    this.getCurrencies();
    // this.getTaxes();
    // this.getDefaultCurrency();
    this.getItems();
    this.getBranches();
    this.getStatusList();
    // let promise = this.getDefaultTax();
    // Promise.all([promise]).then(() => {
    //   if (this.items_list.length <= 0) {
    //     this.addItemRowToList();
    //   }
    // });
    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
      this.getCode();
      this.addItemRowToList();
      this.data.purchase_date = new Date().toISOString().substr(0, 10);
    }

  },
};
</script>